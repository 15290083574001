<template>
  <b-card>
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0 bold">Uploaded Documents</h3>
        </b-col>
      </b-row>
    </template>

    <b-row ref="uploadSection">
      <b-col cols="12" class="mb-5">
        <h4 class="mb-3 bold">Licenses &amp; other documents</h4>
        <p class="mb-0 text-muted">
          Please upload a copy of your current state medical license, DEA
          certificate, government issued ID, and a recent photograph.
        </p>
      </b-col>
      <b-col cols="8">
        <b-alert
          :show="alert.age"
          :variant="alert.type"
          @dismiss-count-down="alertTimer"
          >{{ alert.message }}</b-alert
        >
      </b-col>
      <b-col cols="12" class="mb-5">
        <ul
          class="list-unstyled"
          v-for="(documents, date) in listDocuments"
          :key="date"
        >
          <li>
            <span class="bold">{{ moment(date).format("MMMM DD YYYY") }}</span>
            ({{ documents.length }} files)
          </li>
          <li v-for="(document, index) in documents" :key="index">
            <b-icon icon="file-earmark" class="mr-3"></b-icon>
            <span
              ><a :href="document.previewURL" target="_blank">{{
                document.filename
              }}</a></span
            >
            <span
              ><a
                href="#"
                class="text-primary bold ml-3 text-decoration-underline"
                @click="deleteDocument(document.id)"
                >Remove</a
              ></span
            >
            <!-- <b-button
              variant="link"
              size="sm"
              v-on:click="deleteDocument(document.id)"
              >Remove</b-button
            > -->
          </li>
        </ul>
      </b-col>
      <b-col cols="12">
        <h3>Upload File</h3>
        <dropzone
          id="dropzone"
          :options="dropzoneOptions"
          ref="uploadDocs"
          @vdropzone-success="dropzoneOnSuccess"
          @vdropzone-error="dropzoneOnError"
          @vdropzone-upload-progress="vprogress"
          @vdropzone-file-added="dropzoneFileAdded"
        ></dropzone>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Dropzone from "vue2-dropzone";
const template = () => `
  <ul class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush">
    <li class="list-group-item">
      <div class="row align-items-center">
        <div class="col-auto">

          <!-- Image -->
          <div class="avatar">
            <div class="dz-success-mark"><i class="fe fe-check"></i></div>
            <div class="dz-error-mark"><i class="fe fe-x-circle"></i></div>
            <img class="avatar-img rounded" data-dz-thumbnail>
          </div>

        </div>
        <div class="col ml-n3">

          <!-- Heading -->
          <h4 class="mb-1" data-dz-name>...</h4>

          <!-- Progress -->
          <div class="progress progress-sm">
            <div class="progress-bar dz-upload" data-dz-uploadprogress role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <!-- Text -->
          <small class="text-muted" data-dz-size>...</small>

        </div>
      </div>
    </li>
  </ul>
`;

import { DocumentService } from "@/services/document.service";
import _ from "lodash";

const API_DOCS_URL = `${process.env.VUE_APP_API_URL}/document`;
export default {
  name: "documents",
  components: {
    Dropzone,
  },
  data() {
    return {
      alert: {},
      listDocuments: {},
      lastUploadDoc: "",
      dropzoneTotalFilesize: 0,
      dropzoneUploadedFilesize: 0,
      dropzoneCurrentUpload: 0,
      dropzoneOptions: {
        url: API_DOCS_URL,
        method: "post",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        paramName: "document",
        acceptedFiles: ".jpg,.jpeg,.png,.pdf",
        maxFilesize: 5, // MB
        maxFiles: 20,
        previewTemplate: template(),
        addRemoveLinks: true,
        accept: (file, done) => {
          this.getDocuments();
          done();
        },
      },
    };
  },
  computed: {
    uploadProgress() {
      return Math.round(
        ((this.dropzoneUploadedFilesize + this.dropzoneCurrentUpload) /
          this.dropzoneTotalFilesize) *
          100
      );
    },
  },
  methods: {
    goto(refName) {
      let element = this.$refs[refName];
      element.scrollIntoView();
    },
    alertMessage(type, message, age) {
      if (!age) age = 3;
      this.goto("uploadSection");
      this.alert = {
        age: age,
        type: type,
        message: message,
      };
    },
    alertTimer(count) {
      this.alert.age = count;
    },
    dropzoneOnError(file, message, response) {
      if (response) {
        let errorResponse = JSON.parse(response.response);
        this.alertMessage("warning", `${errorResponse.message}`, 15);
        return;
      }
      this.alertMessage(
        "warning",
        `${file.name} ${message} ${
          file.type == "" ? "Must upload PDF / Image files." : ""
        }`,
        15
      );
    },
    dropzoneOnSuccess(file, response) {
      this.getDocuments();
    },
    dropzoneFileAdded(file) {
      this.dropzoneTotalFilesize += file.size;
    },
    vprogress(file, totalBytes, totalBytesSent) {
      this.dropzoneCurrentUpload = totalBytesSent; // write totalBytes to dropzoneCurrentUpload
      if (file.size <= totalBytesSent) {
        this.dropzoneCurrentUpload = 0; // reset current upload bytes counter
        this.dropzoneUploadedFilesize += totalBytesSent; // add finished file to total upload
      }
    },
    async getDocuments() {
      try {
        const { data: documents } = await DocumentService.getDocuments();
        let tempArrayDocs = [];
        for (let i = 0; i < documents.length; i++) {
          let objDocs = documents[i];
          objDocs.timestampuploaded = this.moment
            .unix(new Date(objDocs.timestampuploaded))
            .format("MM-DD-YYYY");
          tempArrayDocs.push(objDocs);
        }
        this.listDocuments = _.groupBy(tempArrayDocs, "timestampuploaded");
      } catch (error) {
        console.log(error);
      }
    },
    async deleteDocument(id) {
      try {
        await DocumentService.deleteDocument(id);
        this.getDocuments();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  border-bottom: 0;
  padding: 3rem 1.5rem;
}

.card-body {
  height: calc(100vh - 260px);
  overflow: auto;

  // @include media-breakpoint-up(xxl) {
  //   height: calc(100vh - 22vh);
  // }
}

@media (max-width: 768px) {
  .card-body {
    height: auto;
  }
}
</style>

<style lang="scss">
.dz-success-mark,
.dz-error-mark {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 1.5em;
  align-items: center;
  justify-content: center;
}

.dropzone .dz-preview.dz-success .dz-success-mark,
.dropzone .dz-preview.dz-error .dz-error-mark {
  display: flex;
}
</style>
