var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c("b-col", [
                    _c("h3", { staticClass: "mb-0 bold" }, [
                      _vm._v("Uploaded Documents"),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-row",
        { ref: "uploadSection" },
        [
          _c("b-col", { staticClass: "mb-5", attrs: { cols: "12" } }, [
            _c("h4", { staticClass: "mb-3 bold" }, [
              _vm._v("Licenses & other documents"),
            ]),
            _c("p", { staticClass: "mb-0 text-muted" }, [
              _vm._v(
                " Please upload a copy of your current state medical license, DEA certificate, government issued ID, and a recent photograph. "
              ),
            ]),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "b-alert",
                {
                  attrs: { show: _vm.alert.age, variant: _vm.alert.type },
                  on: { "dismiss-count-down": _vm.alertTimer },
                },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-5", attrs: { cols: "12" } },
            _vm._l(_vm.listDocuments, function (documents, date) {
              return _c(
                "ul",
                { key: date, staticClass: "list-unstyled" },
                [
                  _c("li", [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.moment(date).format("MMMM DD YYYY"))),
                    ]),
                    _vm._v(" (" + _vm._s(documents.length) + " files) "),
                  ]),
                  _vm._l(documents, function (document, index) {
                    return _c(
                      "li",
                      { key: index },
                      [
                        _c("b-icon", {
                          staticClass: "mr-3",
                          attrs: { icon: "file-earmark" },
                        }),
                        _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: document.previewURL,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(document.filename))]
                          ),
                        ]),
                        _c("span", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-primary bold ml-3 text-decoration-underline",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteDocument(document.id)
                                },
                              },
                            },
                            [_vm._v("Remove")]
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }),
            0
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("h3", [_vm._v("Upload File")]),
              _c("dropzone", {
                ref: "uploadDocs",
                attrs: { id: "dropzone", options: _vm.dropzoneOptions },
                on: {
                  "vdropzone-success": _vm.dropzoneOnSuccess,
                  "vdropzone-error": _vm.dropzoneOnError,
                  "vdropzone-upload-progress": _vm.vprogress,
                  "vdropzone-file-added": _vm.dropzoneFileAdded,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }